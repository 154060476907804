<template>
  <div ref="playerNameModal" class="modal basic_modal" id="nickname_set" data-bs-backdrop="static" tabindex="-1"
       aria-labelledby="staticBackdropLabel">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content" style="height:26rem;">
        <div class="modal-header">
          <h1 class="modal-title">닉네임 설정</h1>
        </div>
        <div class="modal-body">
          <label for="nickname" class="form-label fs-4">닉네임</label>
          <input
            autocomplete="off"
            type="text"
            id="nickname"
            name="nickname"
            class="form-control form-control-lg"
            placeholder="닉네임을 입력하세요"
            v-model.trim="nickname"
          >
          <div class="form-text fs-5 mt-2" style="color:#aaa;">
            한글과 영어, 숫자를 포함하여 2~10자로 만들어주세요
          </div>
        </div>
        <div class="modal-footer center">
          <button
            class="btn_d"
            :class="{
              btn_gray: !isValidName,
              btn_blue: isValidName,
            }"
            @click.prevent.stop="save"
          >저장
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from 'bootstrap';
import {debounce} from "@/utils/debounce";

export default {
  data() {
    return {
      nickname: '',
      nicknameError: false,
      modalInstance: null,
    };
  },
  computed: {
    isValidLength() {
      const maxLength = 10;
      const strLength = (this.nickname || '').length;

      return strLength <= maxLength;
    },
    isValidFormat() {
      return /^[가-힣a-zA-Z0-9]{2,}$/.test(this.nickname);
    },
    isValidName() {
      return this.isValidFormat && this.isValidLength;
    }
  },
  methods: {
    validate() {
      this.nicknameError = true;

      if (!this.isValidFormat) {
        this.$swal.fire({
          title: '허용되지 않는 문자가 포함되어 있습니다.',
          icon: "error",
        });
        // alert('허용되지 않는 문자가 포함되어 있습니다.');
        return;
      }

      if (!this.isValidLength) {
        this.$swal.fire({
          title: '닉네임 문자 길이를 확인해 주세요.',
          icon: "error",
        });
        // alert('닉네임 문자 길이를 확인해 주세요.');
        return;
      }

      this.nicknameError = false;
    },
    async save() {
      this.validate();

      if (this.nicknameError) {
        return;
      }

      await this.saveNickname();
    },
    saveNickname: debounce(async function () {
      let isSuccess = false;
      let errorMessage = '';

      try {
        const result = await this.$store.dispatch('setPlayerName', this.nickname);

        isSuccess = result && result.ret === 0;

        if (!isSuccess) {
          errorMessage = (result && result.message) || '';
        }
      } catch (result) {
        isSuccess = result && result.ret === 0;

        if (!isSuccess) {
          errorMessage = (result && result.message) || '';
        }
      } finally {
        if (!isSuccess) {
          this.$swal.fire({
            title: errorMessage || '닉네임 설정 중 오류가 발생했습니다. 다시 시도해 주세요.',
            icon: "error",
          });
          //alert(errorMessage || '닉네임 설정 중 오류가 발생했습니다. 다시 시도해 주세요.');
        } else {
          await this.$store.dispatch('auth_check');
          this.$swal.fire({
            title: `[${this.nickname}] 닉네임을 저장하였습니다.`,
            icon: "success",
          }).then(() => {
            this.closeModal();
          });
          // alert(`${this.nickname} 닉네임 설정이 성공적으로 저장되었습니다.`);
        }
      }
    }, 500, {leading: true}),
    closeModal() {
      this.modalInstance.hide();
    }
  },
  mounted() {
    this.modalInstance = new Modal(this.$refs.playerNameModal);
    this.modalInstance.show();
  },
}
</script>

<style>
#nickname_set {
  z-index: 1051 !important;
}
</style>